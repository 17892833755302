<template>
    <div class="header-home">
        <div class="header-home__content">
            <div class="header-home__logo" @click="home"></div>
            <div class="header-home__buttons" v-if="!$route.meta.hideButtons">
                <div class="header-home__buttons__desktop">
                    <template v-for="(item, index) in menuItems">
                        <div :key="`header-button-${index}`" class="header-home__buttons__button" v-scroll-to="item.link">{{ item.title }}</div>
                    </template>
                    <div>
                        <BaseButton class="header-home__buttons__button" small fill @click="$emit('feedback')">Оставить заявку</BaseButton>
                    </div>
                </div>
                <div class="header-home__buttons__mobile">
                    <v-menu transition="slide-y-transition" offset-y hide-on-scroll>
                        <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-icon>mdi-menu</v-icon>
                            </div>
                        </template>
                        <v-list>
                            <template v-for="(item, index) in menuItems">
                                <v-list-item :key="`header-mobile-${index}`" class="header-home__buttons__mobile__item" v-scroll-to="item.link">
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </template>
                            <v-list-item>
                                <BaseButton class="header-home__buttons__button" small fill @click="$emit('feedback')">Оставить заявку</BaseButton>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated } from '../../helpers';
    import { shortAddressFormat } from '../../helpers/user';

    import BaseButton from '../common/BaseButton';

    export default {
        name: 'Header',
        components: {
            BaseButton
        },
        data: () => ({
            imageSrc,
            priceFormated,
            shortAddressFormat,
            menuItems: [
                { link: '#platform', title: 'ПРОДУКТЫ' },
                { link: '#delivery', title: 'ДЕМО' },
                { link: '#tariffs', title: 'ТАРИФЫ' },
                // { link: '#clients', title: 'КЛИЕНТЫ' },
                { link: '#contacts', title: 'КОНТАКТЫ' },
            ]
            
        }),
        computed: {
        },
        async mounted() {
        },
        methods: {
            home() {
                this.$router.push('/');
            },
            setMenu(link) {
                this.$router.push(link);
            },
            logout() {
                store.dispatch('users/logout');
            }
        }
    }
</script>

<style lang="scss">
.header-home {
    width: 100%;
    height: 70px;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    z-index: 10;

    &__content {
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        width: 200px; 
        height: 50px;
        background: url('../../assets/logo-name.svg') left center no-repeat;
        background-size: contain;
        cursor: pointer;

        @media screen and (max-width: 460px) {
            width: 120px;
            background: url('../../assets/logo.svg') left center no-repeat;

            span {
                display: none;
            }
        }
    }

    &__buttons {
        &__button {
            cursor: pointer;
            font-size: 18px;
            line-height: 22px;
            
            @media screen and (max-width: 1400px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
        
        &__desktop {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width: 880px) {
                display: none;
            }
            
            > div {
                margin-left: 20px;
            }
        }

        &__mobile {
            display: none;

            @media screen and (max-width: 880px) {
                display: block;
            }

            &__item {
                min-height: 30px;
                
                .v-list-item__title {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>