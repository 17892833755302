import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-home"},[_c('div',{staticClass:"header-home__content"},[_c('div',{staticClass:"header-home__logo",on:{"click":_vm.home}}),(!_vm.$route.meta.hideButtons)?_c('div',{staticClass:"header-home__buttons"},[_c('div',{staticClass:"header-home__buttons__desktop"},[_vm._l((_vm.menuItems),function(item,index){return [_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:(item.link),expression:"item.link"}],key:`header-button-${index}`,staticClass:"header-home__buttons__button"},[_vm._v(_vm._s(item.title))])]}),_c('div',[_c('BaseButton',{staticClass:"header-home__buttons__button",attrs:{"small":"","fill":""},on:{"click":function($event){return _vm.$emit('feedback')}}},[_vm._v("Оставить заявку")])],1)],2),_c('div',{staticClass:"header-home__buttons__mobile"},[_c(VMenu,{attrs:{"transition":"slide-y-transition","offset-y":"","hide-on-scroll":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}],null,false,2854074075)},[_c(VList,[_vm._l((_vm.menuItems),function(item,index){return [_c(VListItem,{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:(item.link),expression:"item.link"}],key:`header-mobile-${index}`,staticClass:"header-home__buttons__mobile__item"},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)]}),_c(VListItem,[_c('BaseButton',{staticClass:"header-home__buttons__button",attrs:{"small":"","fill":""},on:{"click":function($event){return _vm.$emit('feedback')}}},[_vm._v("Оставить заявку")])],1)],2)],1)],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }