<template>
    <div class="home">
        <Header 
            @feedback="openPopup('connect')"/>
        <div class="home__platform">
            <div class="home__content">
                <div class="home__ancor" id="platform"></div>
                <div>
                    <div class="home__title">
                        Платформа для продвижения собственной доставки ресторана
                    </div>
                    <div class="home__text">
                        Мы предлагаем готовый сайт доставки и самовывоза с широким функционалом и удобной системой управления заказами
                    </div>
                    <div class="home__list">
                        <div class="home__list__item">
                            <span class="home__icon home__icon__ruble"></span> Без комиссии с заказов
                        </div>
                        <div class="home__list__item">
                            <span class="home__icon home__icon__24_hours"></span> Быстрый запуск за один день
                        </div>
                        <div class="home__list__item">
                            <span class="home__icon home__icon__chart"></span> Маркетинговая поддержка и помощь в продвижении
                        </div>
                    </div>
                    <div class="home__buttons">
                        <div>
                            <BaseButton fill small @click="openPopup('connect')">Подключиться</BaseButton>
                        </div>
                        <div>
                            <BaseButton small @click="openPopup('download')">Скачать презентацию</BaseButton>
                        </div>
                    </div>
                </div>
                <div class="home_image">
                    <img src="../assets/home/img_1.png" height="231px">
                </div>
            </div>
        </div>
        <div class="home__why">
            <div class="home__content">
                <div class="home__ancor" id="delivery"></div>
                <div>
                    <div class="home__title">
                        Зачем вам все это?
                    </div>
                    <div class="home__text">
                        Наши продукты помогают развивать собственную доставку ресторанам, кафе и барам.<br>
                        Быстрый запуск, понятный интерфейс и сопровождение по маркетинговым возможностям обеспечивает быстрый рост и дополнительный заработок
                    </div>
                </div>
            </div>
        </div>
        <div class="home__delivery">
            <div class="home__content">
                <div class="home_image">
                    <img src="../assets/home/img_2.png" height="309px">
                </div>
                <div>
                    <div class="home__title">
                        Сайт доставки и самовывоза
                    </div>
                    <div class="home__text">
                        <ul>
                            <li>Функциональный сайт</li>
                            <li>Возможность настройки дизайна</li>
                            <li>Личный кабинет пользователя</li>
                            <li>Маркетинговые возможности</li>
                        </ul>
                    </div>
                    <div class="home__buttons">
                        <div>
                            <BaseButton small @click="$router.push('/the-fox-pub')">Посмотреть демо</BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home__menu">
            <div class="home__content">
                <div>
                    <div class="home__title">
                        Электронное меню
                    </div>
                    <div class="home__text">
                        <ul>
                            <li>Информативное меню по ссылке или QR-коду</li>
                            <li>Работает на любом устройстве</li>
                            <li>Быстрое редактирование позиции</li>
                            <li>Маркетинговые возможности</li>
                        </ul>
                    </div>
                    <div class="home__buttons">
                        <div>
                            <BaseButton small @click="$router.push('/the-fox-pub/menu')">Посмотреть демо</BaseButton>
                        </div>
                    </div>
                </div>
                <div class="home_image">
                    <img src="../assets/home/img_3.png" height="310px">
                </div>
            </div>
        </div>
        <div class="home__admin">
            <div class="home__content">
                <div class="home_image">
                    <img src="../assets/home/img_4.png" height="320px">
                </div>
                <div>
                    <div class="home__title">
                        Система администрирования
                    </div>
                    <div class="home__text">
                        <ul>
                            <li>Интуитивная система работы с заказами</li>
                            <li>Удобный интерфейс для редактирования меню</li>
                            <li>База пользователей с возможностью выгрузки</li>
                            <li>Возможность работы с любого устройства</li>
                        </ul>
                    </div>
                    <div class="home__buttons">
                        <div>
                            <BaseButton small @click="openPopup('demo')">Посмотреть демо</BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home__result">
            <div class="home__content">
                <div>
                    <div class="home__title">
                        Подытожим!
                    </div>
                    <div class="home__text">
                        Вы получаете готовый современный сайт доставки и самовывоза с онлайн-оплатой, электронное меню для вашего ресторана, а также удобную систему управления заказами.<br>
                        Мы поможем быстро запустить вашу собственную доставку и поделимся нашим опытом по продвижению и маркетингу
                    </div>
                </div>
            </div>
        </div>
        <div class="home__tariffs">
            <div class="home__content">
                <div class="home__ancor" id="tariffs"></div>
                <div>
                    <div class="home__title">
                        Тарифы
                    </div>
                    <div class="home__tariffs__content">
                        <div class="home__tariffs__item">
                            <div class="home__tariffs__title">
                                Сайт доставки и самовывоза
                            </div>
                            <div class="home__tariffs__price">
                                от 1990₽ в месяц
                            </div>
                            <div class="home__tariffs__text">
                                <ul>
                                    <li>Функциональный сайт с вашим брендом</li>
                                    <li>Отсутствие комиссии за заказы</li>
                                    <li>Гибкая настройка условий доставки</li>
                                    <li>Размещений акций и промокодов</li>
                                    <li>Возможность онлайн-оплаты</li>
                                    <li>Удобная система работы с заказами</li>
                                    <li>Базовая маркетинговая поддержка от Маркетории</li>
                                    <li>Круглосуточная техническая поддержка</li>
                                </ul>
                            </div>
                            <v-spacer/>
                            <div class="home__tariffs__buttons">
                                <BaseButton small @click="openPopup('connect')">Подключить</BaseButton>
                            </div>
                        </div>
                        <div class="home__tariffs__item">
                            <div class="home__tariffs__title">
                                Электронное меню
                            </div>
                            <div class="home__tariffs__price">
                                от 790₽ в месяц
                            </div>
                            <div class="home__tariffs__text">
                                <ul>
                                    <li>Информативное меню с изображениями и описанием</li>
                                    <li>Оптимизация под все устройства</li>
                                    <li>Доступ по ссылке или QR-коду</li>
                                    <li>Быстрое редактирование из личного кабинета</li>
                                    <li>Обновление в режиме реального времени</li>
                                    <li>Возможность онлайн-оплаты заказа (скоро)</li>
                                    <li>Круглосуточная техническая поддержка</li>
                                </ul>
                            </div>
                            <v-spacer/>
                            <div class="home__tariffs__buttons">
                                <BaseButton small @click="openPopup('connect')">Подключить</BaseButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="home__clients">
            <div class="home__content">
                <div class="home__ancor" id="clients"></div>
                <div>
                    <div class="home__title">
                        Клиенты
                    </div>
                    <div class="home__clients__items">
                        <div class="home__clients__item">
                            <div class="home__clients__image">
                                <img src="../assets/home/clients/vkusno-i-tochka.png">
                            </div>
                            <div class="home__clients__title">
                                Вкусно и точка
                            </div>
                        </div>
                        <div class="home__clients__item">
                            <div class="home__clients__image">
                                <img src="../assets/home/clients/burger-king.png">
                            </div>
                            <div class="home__clients__title">
                                Burger king
                            </div>
                        </div>
                        <div class="home__clients__item">
                            <div class="home__clients__image">
                                <img src="../assets/home/clients/taco-bell.png">
                            </div>
                            <div class="home__clients__title">
                                Tako bell
                            </div>
                        </div>
                        <div class="home__clients__item">
                            <div class="home__clients__image">
                                <img src="../assets/home/clients/kroshka-kartoshka.png">
                            </div>
                            <div class="home__clients__title">
                                Крошка-картошка
                            </div>
                        </div>
                        <div class="home__clients__item">
                            <div class="home__clients__image">
                                <img src="../assets/home/clients/chicken-house.png">
                            </div>
                            <div class="home__clients__title">
                                Чикен хауз
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="home__contacts">
            <div class="home__content">
                <div class="home__ancor" id="contacts"></div>
                <div>
                    <div class="home__title">
                        Остались вопросы?
                    </div>
                    <div class="home__text">
                        Свяжитесь с нами или оставьте свои контакты<br>
                        Мы свяжемся в ближайшее время и подробно проконсультируем
                    </div>
                    <div class="home__contacts__content">
                        <div class="home__contacts__item">
                            <div class="home__contacts__phone">
                                <a href="tel:79936173805">+7 993 617 38 05</a>
                            </div>
                            <div class="home__contacts__email">
                                <a href="mailto:partners@marketoria.com">partners@marketoria.com</a>
                            </div>
                        </div>
                        <div class="home__contacts__item">
                            <ValidationObserver ref="contacts" slim>
                                <ValidationProvider v-slot="{ errors }" rules="required|email">
                                    <BaseTextField
                                        v-model="forms.contacts.email"
                                        label="Ваша почта"
                                        small
                                        :error-messages="errors[0]"
                                    />
                                </ValidationProvider>
                                <ValidationProvider v-slot="{ errors }" rules="required">
                                    <BaseTextField
                                        v-model="forms.contacts.name"
                                        label="Как к Вам обращаться?"
                                        small
                                        :error-messages="errors[0]"
                                    />
                                </ValidationProvider>
                                <BaseTextArea
                                    v-model="forms.contacts.comment"
                                    label="Здесь можно описать Ваш проект и задачи"
                                    small
                                    rows=3
                                />
                                <ValidationProvider v-slot="{ errors }" rules="required">
                                    <BaseTextField
                                        v-model="forms.contacts.phone"
                                        label="Ваш телефон"
                                        small
                                        :error-messages="errors[0]"
                                    />
                                </ValidationProvider>
                                <div>
                                    <BaseButton small @click="sendForm('contacts')">Отправить</BaseButton>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>  
                    <div class="home__contacts__docs">
                        <a :href="VUE_APP_DOC_SITE_AGREEMENT" target="_blank">Соглашение об использовании сайта и персональных данных</a>
                        <a :href="VUE_APP_DOC_USER_AGREEMENT" target="_blank">Пользовательское соглашение</a>
                        <a :href="VUE_APP_DOC_OFFER" target="_blank">Оферта</a>
                    </div>
                </div>
            </div>
        </div>

        <BasePopup v-model="forms.connect.shown" @close="closePopup('connect')">
            <div class="home__popup">
                <div class="home__title">Заполните заявку на подключение</div>
                <div class="home__text">Менеджер свяжется и расскажет подробнее о наших решениях</div>
                <ValidationObserver ref="connect" slim>
                    <div class="home__content">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="forms.connect.name"
                                label="Ваше имя"
                                small
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="forms.connect.phone"
                                label="Телефон для связи"
                                small
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </div>
                    <div class="home__buttons">
                        <div>
                            <BaseButton small fill @click.stop="sendForm('connect')">Подключиться</BaseButton>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </BasePopup>

        <BasePopup v-model="forms.download.shown" @close="closePopup('download')">
            <div class="home__popup">
                <div class="home__title">Оставьте вашу почту и мы направим презентацию</div>
                <ValidationObserver ref="download" slim>
                    <div class="home__content">
                        <ValidationProvider v-slot="{ errors }" rules="required|email">
                            <BaseTextField
                                v-model="forms.download.email"
                                label="Ваш email"
                                small
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </div>
                    <div class="home__buttons">
                        <div>
                            <BaseButton small fill @click.stop="sendForm('download')">Отправить</BaseButton>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </BasePopup>

        <BasePopup v-model="forms.demo.shown" @close="closePopup('demo')">
            <div class="home__popup">
                <div class="home__title">Оставьте заявку для проведения демо</div>
                <div class="home__text">Наш менеджер в режиме онлайн покажет и расскажет про функционал системы администрирования</div>
                <ValidationObserver ref="demo" slim>
                    <div class="home__content">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="forms.demo.name"
                                label="Ваше имя"
                                small
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="forms.demo.phone"
                                label="Телефон для связи"
                                small
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </div>
                    <div class="home__buttons">
                        <div>
                            <BaseButton small fill @click.stop="sendForm('demo')">Отправить</BaseButton>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </BasePopup>

        <BasePopup v-model="successShown" @close="successShown = false">
            <div class="home__popup">
                <div class="home__title">Ваша заявка успешно отправлена</div>
                <div class="home__text">Наш менеджер свяжется с вами в ближайшее время</div>
                <div class="home__content"></div>
                <div class="home__buttons">
                    <div>
                        <BaseButton small fill @click.stop="successShown = false">Понятно</BaseButton>
                    </div>
                </div>
            </div>
        </BasePopup>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import Header from '../components/home/Header';
    import BaseButton from '../components/common/BaseButton';
    import BaseTextField from '../components/common/BaseTextField';
    import BaseTextArea from '../components/common/BaseTextArea';
    import BasePopup from '../components/common/BasePopup';

    const { VUE_APP_DOC_SITE_AGREEMENT, VUE_APP_DOC_USER_AGREEMENT, VUE_APP_DOC_OFFER } = process.env;

    export default {
        name: 'Home',
        metaInfo() {
            return {
                title: 'Платформа для продвижения собственной доставки ресторана'
            }
        },
        components: {
            ValidationProvider, 
            ValidationObserver,
            Header,
            BaseButton,
            BaseTextField,
            BaseTextArea,
            BasePopup
        },
        data: () => ({
            VUE_APP_DOC_SITE_AGREEMENT, 
            VUE_APP_DOC_USER_AGREEMENT, 
            VUE_APP_DOC_OFFER,
            forms: {
                connect: {
                    form: 'home-connect',
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    shown: false
                },
                download: {
                    form: 'home-download',
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    shown: false
                },
                demo: {
                    form: 'home-demo',
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    shown: false
                },
                contacts: {
                    form: 'home-contacts',
                    name: '',
                    phone: '',
                    email: '',
                    comment: ''
                }
            },
            successShown: false,
            emptyForm: {
                name: '',
                phone: '',
                email: '',
                comment: ''
            }
        }),
        computed: {
        },
        async mounted() {
            store.commit('entities/CLEAR_ENTITY');
        },
        methods: {
            openPopup(form) {
                this.forms[form].shown = true;
            },
            closePopup(form) {
                this.forms[form].shown = false;
            },
            async sendForm(form) {
                const valid = await this.$refs[form].validate();
                if(valid) {
                    store.commit('feedbacks/SET_ENTITY', this.forms[form]);
                    await store.dispatch('feedbacks/save');
                    this.forms[form] = {
                        ...this.forms[form],
                        ...this.emptyForm
                    }
                    this.$refs[form].reset();
                    this.closePopup(form);
                    this.successShown = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .home {
        width: 100%;
        background: #FFFFFF;

        &__content {
            position: relative;
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow-x: hidden;
        }
        
        &__ancor {
            position: absolute;
            top: -70px;
            left: 0;
        }

        &__title {
            font-weight: 800;
            font-size: 45px;
            line-height: 45px;
            padding-bottom: 15px;
            
            @media screen and (max-width: 1400px) {
                font-size: 35px;
                line-height: 35px;
            }
        }

        &__text {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            padding-bottom: 15px;

            li {
                padding: 5px 0;
            }
            
            @media screen and (max-width: 1400px) {
                font-size: 14px;
                line-height: 18px;
            }
        }

        &__list {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            padding-bottom: 15px;
            
            @media screen and (max-width: 1400px) {
                font-size: 14px;
                line-height: 16px;
            }

            &__item {
                display: flex;
                align-items: center;
                padding-bottom: 5px;
            }
        }

        &__buttons {
            display: flex;

            > div {
                margin-right: 10px;
            }
        }

        &__icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: #4F4F4F;
            border-radius: 8px;
            margin-right: 5px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 12px 12px; 

            &__ruble {
                background-image: url('../assets/home/icon_ruble.svg');
            }
            &__24_hours {
                background-image: url('../assets/home/icon_24_hours.svg');
            }
            &__chart {
                background-image: url('../assets/home/icon_chart.svg');
            }
        }

        &__image {
            width: 100%;
            max-width: 400px;

            > img {
                width: 100%;
            }
        }

        &__popup {
            .home__content {
                width: 90%;
                max-width: 450px;
                padding: 20px 0;
                display: block;
            }
            .home__title,
            .home__text {
                text-align: center;
                margin: 0 auto;
                width: 90%;
                max-width: 450px;
            }
            .home__buttons {
                justify-content: center;
            }
        }

        @media screen and (min-width: 1401px) {
            .base-button.button-small {
                height: 40px;
                border-radius: 8px;

                .v-btn__content {
                    font-size: 14px;
                    line-height: 19px;
                }

                &.theme--light.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                }
            }
        }

        &__platform {
            background: url('../assets/home/bg.png');

            .home__content {
                max-width: 1150px;
                padding: 40px 0;
            
                @media screen and (max-width: 1400px) {
                    max-width: 980px;
                }
                
                @media screen and (max-width: 710px) {
                    flex-direction: column-reverse;
                    padding: 20px 0;
                }

                > div {
                    @media screen and (max-width: 710px) {
                        padding: 20px;
                    }
                }
            }
            .home__text {
                max-width: 430px;
            }
        }

        &__why {
            .home__content {
                max-width: 580px;
                padding: 30px 0;

                > div {
                    @media screen and (max-width: 710px) {
                        padding: 10px 20px;
                    }
                }
            }
            .home__title,
            .home__text {
                text-align: center;
            }
        }
        &__delivery {
            background: url('../assets/home/line_1.png') center bottom 145px no-repeat;
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
            margin-left: -120px;
            padding-left: 120px;
            
            @media screen and (max-width: 1400px) {
                margin-left: 0;
                padding-left: 0;
            }
            
            @media screen and (max-width: 710px) {
                background: none;
            }

            .home__content {
                max-width: 1050px;
                padding: 96px 0;
            
                @media screen and (max-width: 1400px) {
                    max-width: 900px;
                }
                
                @media screen and (max-width: 710px) {
                    flex-direction: column;
                    padding: 20px 0;
                }

                > div {
                    @media screen and (max-width: 710px) {
                        padding: 20px;
                    }
                }
            }
        }
        &__menu {
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);

            .home__content {
                max-width: 830px;
                padding: 77px 0;
            
                @media screen and (max-width: 1400px) {
                    max-width: 730px;
                }
                
                @media screen and (max-width: 710px) {
                    flex-direction: column-reverse;
                    padding: 20px 0;
                }

                > div {
                    @media screen and (max-width: 710px) {
                        padding: 20px;
                    }
                }
            }
        }
        &__admin {
            background: url('../assets/home/line_2.png') bottom 120px center no-repeat;
            margin-left: -120px;
            padding-left: 120px;
            
            @media screen and (max-width: 1400px) {
                margin-left: 0;
                padding-left: 0;
            }
            
            @media screen and (max-width: 710px) {
                background: none;
            }

            .home__content {
                max-width: 1070px;
                padding: 100px 0;
            
                @media screen and (max-width: 1400px) {
                    max-width: 930px;
                }
                
                @media screen and (max-width: 710px) {
                    flex-direction: column;
                    padding: 20px 0;
                }

                > div {
                    @media screen and (max-width: 710px) {
                        padding: 20px;
                    }
                }
            }
        }
        &__result {
            .home__content {
                max-width: 800px;
                padding: 30px 0;

                > div {
                    @media screen and (max-width: 710px) {
                        padding: 10px 20px;
                    }
                }
            }
            .home__title,
            .home__text {
                text-align: center;
            }
        }

        &__tariffs {
            background: #4F4F4F url('../assets/home/line_3.png') bottom 27px center no-repeat;
            
            @media screen and (max-width: 710px) {
                background: #4F4F4F;
            }

            .home__title {
                text-align: center;
                color: #FFFFFF;
            }
            .home__content {
                justify-content: center;
                padding: 40px 0 60px;
                
                @media screen and (max-width: 710px) {
                    padding: 20px;
                }
            }

            &__content{
                display: flex;
                justify-content: center;
                
                @media screen and (max-width: 710px) {
                    flex-direction: column;
                    padding: 10px 0;
                }

                > div {
                    @media screen and (max-width: 710px) {
                        padding: 20px;
                    }
                }
            }
            &__item {
                max-width: 434px;
                margin: 0 10px;
                background: #FFFFFF;
                border: 4px solid #EB9F2C;
                border-radius: 30px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            
                @media screen and (max-width: 1400px) {
                    max-width: 384px;
                }
                
                @media screen and (max-width: 710px) {
                    margin: 10px 0;
                }
            }
            &__title {
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
                text-transform: uppercase;
                padding-bottom: 20px;
            
                @media screen and (max-width: 1400px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            &__price {
                font-weight: 800;
                font-size: 35px;
                line-height: 45px;
                padding-bottom: 20px;
            
                @media screen and (max-width: 1400px) {
                    font-size: 25px;
                    line-height: 35px;
                }
            }
            &__text {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                padding-bottom: 20px;
            
                @media screen and (max-width: 1400px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
        &__clients {
            .home__content {
                padding: 40px 0;
                display: block;
            }
            .home__title {
                text-align: center;
            }
            &__items {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                padding-top: 5px;
            }
            &__image {
                width: 150px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__title {
                padding-top: 10px;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                text-align: center;
            }
        }

        &__contacts {
            background: url('../assets/home/bg.png');

            .home__content {
                max-width: 980px;
                padding: 40px 0 20px;
                justify-content: center;

                > div {
                    width: 100%;
                }
                
                @media screen and (max-width: 710px) {
                    padding: 40px 20px;
                }
            }
            .home__title,
            .home__text {
                text-align: center;
            }

            &__phone {
                font-weight: 700;
                font-size: 36px;
                line-height: 40px;
                margin-bottom: 10px;

                > a {
                    color: var(--text_color) !important;
                    text-decoration: none !important;
                }
            }
            &__email {
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                margin-bottom: 20px;

                > a {
                    color: var(--text_color) !important;
                    text-decoration: none !important;
                }
            }
            &__content {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 30px;
                
                @media screen and (max-width: 710px) {
                    flex-direction: column;
                    padding: 0;
                }
            }
            &__item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                max-width: 384px;
                margin: 0 10px;

                span {
                    width: 100%;
                }
                
                @media screen and (max-width: 710px) {
                    align-items: center;
                }
            }
            &__docs {
                width: 100%;
                padding: 20px 0 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 30px;

                @media screen and (max-width: 710px) {
                    flex-direction: column;
                }
            }
        }
    }
</style>
